frappe.ui.GroupBy = class GroupBy extends frappe.ui.GroupBy {
    toggle_aggregate_on_field() {
		let fn = this.aggregate_function_select.val();
		if (fn === "sum" || fn === "avg") {
			if (!this.aggregate_on_html.length) {
				this.aggregate_on_html = `<option value="" disabled selected>
						${__("Select Field...")}
					</option>`;

				for (let doctype in this.all_fields) {
					if (doctype !== this.doctype) {
						continue; // Skip other doctypes' fields
					}

					const doctype_fields = this.all_fields[doctype];
					doctype_fields.forEach((field) => {

						// pick numeric fields for sum / avg
						if (frappe.model.is_numeric_field(field.fieldtype)) {
							let field_label = field.label || frappe.model.unscrub(field.fieldname);
							let option_text =
								doctype == this.doctype
									? __(field_label, null, field.parent)
									: `${__(field_label, null, field.parent)} (${__(doctype)})`;
							this.aggregate_on_html += `<option data-doctype="${doctype}"
								value="${field.fieldname}">${option_text}</option>`;
						}
					});
				}
			}
			this.aggregate_on_select.html(this.aggregate_on_html);
			this.toggle_aggregate_on_field_display(true);
		} else {
			// count, so no aggregate function
			this.toggle_aggregate_on_field_display(false);
		}
	}
};
